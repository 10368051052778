import React from 'react';
import { Layout } from '@talves/gatsby-theme-site-layout';

// Components
import { PostsWithData } from '../../components/Posts/data';
const Posts = ({ pageContext = {} }) => {
  return (
    <Layout header={pageContext.title}>
      <PostsWithData />
    </Layout>
  );
};

export default Posts;
