import React from 'react';
import { Link } from 'gatsby';
import { GatsbyLinkButton } from '@talves/gatsby-theme-components-tui';
import { Box, useThemeUI, useColorMode } from '../../provider';

function setupColors(state, action) {
  const textColor =
    (action.theme && action.theme.colors && action.theme.colors.text) ||
    'black';
  const bgColor =
    (action.theme && action.theme.colors && action.theme.colors.background) ||
    'white';
  const borderColor =
    (action.theme && action.theme.colors && action.theme.colors.muted) ||
    'white';
  switch (action.colorMode) {
    case 'dark':
      return {
        textColor,
        bgColor,
        borderColor,
      };
    default:
      return {
        textColor,
        bgColor,
        borderColor,
      };
  }
}

const PostsListItem = ({ data }) => {
  const { theme } = useThemeUI();
  const [colorMode] = useColorMode();
  const [state, dispatch] = React.useReducer(setupColors, {});

  React.useEffect(() => {
    dispatch({ theme, colorMode });
  }, [theme, colorMode]);

  return (
    <Box
      sx={{
        position: 'relative',
        margin: '1rem',
        color: state.textColor,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          zIndex: 3,
          mb: '60px',
          padding: '20px 40px 40px',
          border: `1px solid ${state.borderColor}`,
          borderRadius: '5px',
          backgroundColor: state.bgColor,
          // boxShadow:
          //   '0 0.4px 3px rgba(0, 0, 0, 0.054),  0.5px 0.5px 8.5px rgba(0, 0, 0, 0.085),  1px 1px 15px rgba(0, 0, 0, 0.15)',
          // boxShadow:
          //   '0 0.4px 3px rgba(209, 234, 242, 0.054),  0.5px 0.5px 8.5px rgba(209, 234, 242, 0.085),  1px 1px 15px rgba(209, 234, 242, 0.15)',
        }}
      >
        <Box>
          <Box
            as={Link}
            to={data.path}
            sx={{
              display: 'block',
              textDecoration: 'none',
              borderStyle: 'none',
              '&:hover': {
                outline: 0,
              },
            }}
            variant="styles.Link"
          >
            <Box
              as="h2"
              sx={{
                mt: 15,
                mb: 10,
                color: 'accent',
              }}
              variant="styles.h2"
            >
              {data.title}
            </Box>
          </Box>
        </Box>
        <Box
          as="p"
          variant="styles.p"
          sx={{
            fontSize: '18px',
            fontWeight: 500,
          }}
        >
          {data.description}
        </Box>
        <GatsbyLinkButton to={data.path}>Read</GatsbyLinkButton>
      </Box>
    </Box>
  );
};

export default PostsListItem;
