import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Posts from './index';

export const fragment = graphql`
  fragment PostsCollection on Posts {
    slug
    rawBody
    name
    frontmatter {
      date
      draft
      path
      title
    }
    id
    internal {
      type
    }
    parent {
      ... on MarkdownRemark {
        id
        excerpt
      }
    }
  }
`;

export default function withData(Component) {
  const WithDataComponent = props => {
    return (
      <StaticQuery
        query={graphql`
          query {
            allPosts {
              nodes {
                ...PostsCollection
              }
            }
          }
        `}
        render={data => <Component data={data.allPosts.nodes} {...props} />}
      />
    );
  };
  return WithDataComponent;
}

export const PostsWithData = withData(props => <Posts {...props} />);
