import React from 'react';
import { Box, useThemeUI } from '../../provider';
import PostsListItem from './list-item';

const Flex = ({ children }) => <Box>{children}</Box>;

const PostsList = ({ data }) => {
  const context = useThemeUI();

  return (
    <Flex>
      {data &&
        data
          .filter(
            t =>
              !t.frontmatter.draft &&
              t.internal.type &&
              t.internal.type === 'Posts',
          )
          .sort((a, b) => {
            return Date.parse(a.frontmatter.date) <
              Date.parse(b.frontmatter.date)
              ? 1
              : -1;
          })
          .map((item, key) => {
            const dataItem = {
              image: item.frontmatter.image,
              title: item.frontmatter.title,
              description: item.parent.excerpt,
              path: item.frontmatter.path,
            };
            return <PostsListItem key={key} data={dataItem} />;
          })}
      {false && (
        <Box>
          <Box as="pre">{JSON.stringify(data, null, 2)}</Box>
          <Box as="pre">{JSON.stringify(context, null, 2)}</Box>
        </Box>
      )}
    </Flex>
  );
};

export default PostsList;
